import 'promise-polyfill/src/polyfill';
import './components/includes-polyfill'
import '../styles/main.scss'
import '../../MyFontsWebfontsKit.css'
import axios from 'axios'
import $ from 'jquery'
import { resolve } from 'dns'
import { saveAs } from 'file-saver'

class SpeakupPosterGenerator {
    constructor() {
        this.options = {
            currentStep: 1,
            maxSteps: 6,
            language : 'en',
            format: null,
            motive: null,
            phone_number: null,
            access_code: null,
            modifyUrl: 'swissport-modify-pdf/',
            downloadUrl: 'swissport-download-pdf/',
            deleteUrl: 'swissport-sanitize-download/',
            homeUrl: 'https://swissport-speakup-posters.com/',
        }

        this.liveUrl = 'https://convert-utils.onrender.com/'
        this.devUrl = 'http://localhost:5000/'

        this.isDev = window.location.hostname == 'localhost' 
        this.host = this.isDev ? this.devUrl : this.liveUrl

        this.queryString = window.location.search

        this.setSessionStorageDatetimeForPdfName()
        this.selectOnChange()
        this.inputChange()

        this.changeMotives()
        this.clickOnDownload()
        this.clickOnMotif()

        window.onbeforeunload = (e) => {
            if ( sessionStorage.getItem('rpdfname') != '') {
                const deleteUrl = `${this.host}${this.options.deleteUrl}?pdfname=${sessionStorage.getItem('rpdfname')}`  
                axios.get( deleteUrl ).then( (response) => {
                    if ( response.data == 'done') { 
                        sessionStorage.clear()
                    }
                })
            }
        }
    }

    changeMotives() {
        $('.motif-row__image').each((key,item) => {
            const $item = $(item)
            const image = $item.attr('data-value')
            const language = this.options.language == 0 ? 'en' : this.options.language
            const imageName = `${image}_${language}.jpg`
            $item.find('img').attr('src', `${this.options.homeUrl}assets/${imageName}`)
        })
    }

    clickOnMotif() {
        $(document).on('click', '.motif-row__image', (e) => {
            $('.motif-row__image').removeClass('active')
            const $e = $(e.currentTarget)
            $('select[name="motive"]').val($e.attr('data-value')).change()
            $e.addClass('active')
        }) 
    }

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    setSessionStorageDatetimeForPdfName() {
        const secret = `${this.getRandomInt(10000,30000)}`
        sessionStorage.setItem('rpdfn', secret)
    }

    setFormValue(name, value, step) {
        $(`form input[name="${name}"]`).val(value)
        this.options['currentStep'] = step
        this.options[name] = value

        this.loadPdf(step, name)
    }

    selectOnChange() {
        $(document).on('change', '.form select', (e) => {
            $('.motif-row__image').removeClass('active')
            const $selectField = $(e.currentTarget)
            const name = $selectField.attr('name')
            const value = $selectField.val()
            const currentStep = parseInt($selectField.attr('data-step'))

            this.setStatusLastStep(0)
            this.options[name] = value

            if ( name == 'language' ) {
                this.changeMotives()
                const $options = $('select[name="format"] option')
                $options.each( (k, item) => {
                    const $item = $(item)
                    const availableLanguages = JSON.parse( $item.attr('data-language') )
                    if ( availableLanguages.includes( this.options.language ) ) {
                        $item.prop('disabled', false)
                    } else {
                        $item.prop('disabled', true)
                    }
                })
            }

            if ( value == 0 ) {
                this.setActiveStepRing(currentStep, 0)
                for ( let i = 6; i > currentStep; i-- ) {
                    this.setActiveStepRing(i, 0)
                    this.deactivateStep(i)
                }
            } else {
                for ( let i = 6; i > currentStep; i-- ) {
                    this.setActiveStepRing(i, 0)
                    this.deactivateStep(i)
                }
            }
            this.setFormValue(name, value, currentStep)
        }) 
    }


    deleteDownloadClearStorage() {
        const deleteUrl = `${this.host}${this.options.deleteUrl}?pdfname=${sessionStorage.getItem('rpdfname')}`  
        axios.get( deleteUrl ).then( (response) => {
            if ( response.data == 'done') { 
                sessionStorage.clear()
            }
        })
    }

    clickOnDownload() {
        $(document).on('click', '.form__row[data-step="6"]', (e) => {
            const $e = $(e.currentTarget)
            if ( $e.attr('data-active') == 0 ) {
                e.preventDefault()
                return;
            }

            const pdfName = `${sessionStorage.getItem('rpdfn')}_Speakup_poster.pdf`
            const url = `${this.host}${this.options.downloadUrl}?pdfname=${pdfName}`
            const downloadPdfName = `${this.options.motive}_${this.options.language}_${this.options.format.substring(1)}.pdf`
            saveAs.saveAs(url, downloadPdfName)
        })
    }

    loadPdf(step) {
        if ( step == 1 ) {
            this.hidePreviewDiv(true)
            if ( this.options.language != 0 && this.options.language != null ) {
                this.activateNextStep(step)
            }
        } else {
            if ( this.options.format != null && this.options.format != 0 ) {
                this.setLoadingScreen(1)
                const pdfName = `${sessionStorage.getItem('rpdfn')}_Speakup_poster.pdf`
                const filter = `?secret=${sessionStorage.getItem('rpdfn')}&format=${this.options.format}&language=${this.options.language}&motive=${this.options.motive}&phone_number=${this.options.phone_number}&access_code=${this.options.access_code}`
                sessionStorage.setItem('rpdfname', `${sessionStorage.getItem('rpdfn')}_Speakup_poster.pdf`)

                axios.get(`${this.host}${this.options.modifyUrl}${filter}`)
                    .then(response => {
                        if ( response.data == 'Err') {
                            this.hidePreviewDiv(true)
                        } else {
                            this.hidePreviewDiv()
                            const imgSrc = this.isDev ? response.data.replace(this.liveUrl, this.devUrl) : response.data

                            $('#image')
                                .attr('src', imgSrc)
                                .on('load', () => { this.setLoadingScreen(0) })

                            this.activateNextStep(step)
                            if ( step == 5) {
                                this.setStatusLastStep(1)
                            }
                        }
                    })
            } else {
                this.hidePreviewDiv(true)
            }
        }
    }

    hidePreviewDiv(hide = false) {
        const $previewInfoDiv = $('.preview__no-preview')
        const $previewDiv = $('.preview__file')
        const $previewDivPDFContainer = $('.preview__pdf')

        if ( hide ) {
            $previewDiv.hide()
            $previewInfoDiv.show()    
            $previewDivPDFContainer.addClass('with-border')
        } else {
            $previewDiv.show()
            $previewInfoDiv.hide()
            $previewDivPDFContainer.removeClass('with-border')
        }
    }

    inputChange() {
        let timeout = null
        $(document).on('keyup', 'input[type="text"]', (e) => {
            const $input = $(e.currentTarget)
            const workingStep = parseInt($input.attr('data-step'))
            this.setLoadingScreen(1)
            this.setStatusLastStep(0)
            clearTimeout(timeout)
            timeout = setTimeout( () => {
                const value = $input.val()
                const name = $input.attr('name')

                if ( value.length > 0 ) {
                    this.setFormValue(name, value, workingStep)
                }
            }, 1000)
        });
    }

    setStatusLastStep(status) {
        const $lastStep = $(`.form__row[data-step="6"]`)
        const $lastStepShadow = $(`.form__row[data-step="6"] .form__shadow`)
        const $beforeDivider = $(`.form__divider[data-step="5_6"]`)

        $lastStep.attr('data-active', status)        
        $beforeDivider.attr('data-active', status)
        if ( status == 0 ) {
            $lastStepShadow.show()
        } else {
            $lastStepShadow.hide()
        }
    }

    activateNextStep(currentStep) {
        const nextStep = parseInt(currentStep) + 1 
        const $nextFormRow = $(`.form__row[data-step="${nextStep}"]`)
        const $nextDivider = $(`.form__divider[data-step="${currentStep}-${nextStep}"]`)
        const $currentFormRow = $(`.form__row[data-step="${currentStep}"]`)

        if ( ( nextStep == 4  || nextStep == 5 ) && $currentFormRow.find('select').val() != 0 ) {
            if ( $nextFormRow.find('input').val().length > 0 ) {
                if ( nextStep <= this.options.maxSteps ) {
                    $nextDivider.attr('data-active', 1)
                    $nextFormRow.attr('data-active', 1)
                    this.setActiveStepRing(currentStep, 1)
                    this.activateNextStep(nextStep)
                }
            } else {
                if ( nextStep <= this.options.maxSteps ) {
                    $nextDivider.attr('data-active', 1)
                    $nextFormRow.attr('data-active', 1)
                    this.setActiveStepRing(currentStep, 1)
                }
            }
        } else {
            if ( nextStep <= this.options.maxSteps && $currentFormRow.find('select').val() != 0 ) {
                $nextDivider.attr('data-active', 1)
                $nextFormRow.attr('data-active', 1)
                this.setActiveStepRing(currentStep, 1)
            }
        }
    }

    deactivateStep(step) {
        this.options['currentStep'] = step
        const $currentFormRow = $(`.form__row[data-step="${step}"]`)
        const $previousDivider = $(`.form__divider[data-step="${step -1}-${step}"]`)
        const name = $currentFormRow.find('select').attr('name')

        $currentFormRow.attr('data-active', 0)
        $currentFormRow.find('select').val(0)
        $previousDivider.attr('data-active', 0)

        $(`form input[name="${name}"]`).val(0)
        this.options[name] = 0
    }

    setActiveStepRing(step, status) {
        $(`.form__row[data-step="${step}"]`).find('.form__step').attr('data-active', status)
    }

    setLoadingScreen(status) {
        const $loadingField = $('.preview__loading')
        status == 0 ? $loadingField.removeClass('load') : $loadingField.addClass('load')
    }


}


window.onload = () => {
    new SpeakupPosterGenerator
}